import React from 'react';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Footer from '../../components/Footer';
import StatsList from '../../components/StatsList';
import WhatCanYouExpect from '../../components/What-can-you-expect';
import AlumniGraduates from '../../components/Alumni-graduates';
import ContextualCta from '../../components/ContextualCta';
import TeamComponent from '../../components/TeamComponent';
import Layout from '../../components/atoms/layout';
import { Title1 } from '../../components/atoms/headers';
import { Text3 } from '../../components/atoms/body';
import { LayoutMethodology, LayoutPadding } from './styles';
import Props from '../../interface/template';
import BlogArticlesSample from '../../components/BlogArticlesSample';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

const CareerServices: React.SFC<any> = (props: Props) => {
  const { t, pageContext } = props;
  const { locale, pageName } = pageContext;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('career-services:careerServices.mainHeadline.subtitle')}
          title={t('career-services:careerServices.mainHeadline.title')}
        />
      </Header>
      <Layout extend={LayoutMethodology}>
        <Title1 as="h2">
          {t('career-services:careerServices.methodology.title')}
        </Title1>
        <Text3 as="p">
          {t('career-services:careerServices.methodology.description.01')}
        </Text3>
        <Text3 as="p">
          {t('career-services:careerServices.methodology.description.02')}
        </Text3>
      </Layout>
      <Layout>
        <StatsList context="career_services" />
      </Layout>
      <WhatCanYouExpect extend={LayoutPadding} />
      <AlumniGraduates extend={LayoutPadding} />
      <ContextualCta />
      <TeamComponent locale={locale} />
      <BlogArticlesSample category="outcomes" />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(career-services)|(staff)|(eyebrow)|(breadcrumbs)|(contextual-cta)|(seo)|(banner)|(blog)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(CareerServices);

import styled from 'styled-components';
import config from '../config';

export default styled.article`
  display: flex;
  flex-direction: column;
  margin-right: 3.2rem;
  text-decoration: none;
  width: calc(16.6667% - 3.2rem);

  img {
    border-radius: 0.6rem;
    height: inherit;
    object-fit: cover;
    width: 100%;
  }

  & > section {
    border-radius: 0.6rem;
    height: 16rem;
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
    width: 16rem;

    > div.profile-picture {
      border-radius: 0.6rem;
      height: inherit;
      object-fit: cover;
      width: 100%;
    }
  }

  & > section > p {
    background-color: #ffd35c;
    border-radius: 0.6rem;
    color: ${config.palette.darkBlue};
    font-size: 1.3rem;
    height: 100%;
    line-height: 2rem;
    overflow-y: scroll;
    padding: 1.6rem;
    position: absolute;
    top: 0;
    transform-origin: bottom;
    transform: scaleY(0);
    transition: transform 0.5s ease-out;
  }

  &:hover > section {
    border-radius: 0.6rem;
    box-shadow: 0 1.6rem 3.2rem 0 rgba(0, 0, 0, 0.1);
  }

  &.description {
    &:hover > section > p {
      transform: scaleY(1);
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 3.2rem;
    width: 100%;

    &:last-child {
      margin: 0;
    }

    & > section {
      height: 12rem;
      width: 12rem;
    }
  }
`;

export const P = styled.p`
  color: ${config.palette.darkBlue};
  font-size: 1.4rem;
  line-height: 2.3rem;
  opacity: 0.6;

  @media (max-width: ${config.resolutions.mobileL}) {
    line-height: 150%;
  }
`;

export const Div = styled.div`
  margin-top: 1.2rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    display: flex;
    flex-direction: column;
    margin-left: 1.6rem;
    max-width: 40%;
  }
`;

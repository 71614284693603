import styled, { css } from 'styled-components';
import config from '../../components/config';

export const P = styled.p`
  font-size: 1.4rem;
  line-height: 2.3rem;
  color: ${config.palette.darkBlue};
  opacity: 0.6;

  @media (max-width: ${config.resolutions.mobileL}) {
    line-height: 150%;
  }
`;

const LayoutMethodology = css`
  padding-bottom: 5.1rem;

  & > p {
    margin-top: 1.6rem;
    opacity: 0.6;
  }
`;

const LayoutStatList = css`
  padding-bottom: 6.4rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-bottom: 3.2rem;

    h1 {
      font-size: 2.8rem;
      line-height: 130%;
    }

    b {
      font-size: 1.4rem;
      line-height: 129%;
    }
  }
`;

const LayoutPadding = css`
  padding-bottom: 6.4rem;
`;

export { LayoutMethodology, LayoutStatList, LayoutPadding };

import fetch from 'node-fetch';

interface Company {
  company: string;
  campus: string;
  alumnus: string;
  position: string;
  courseEditionYear: number;
  courseEditionMonth: number;
  featured?: boolean;
  top?: boolean;
}

export default class AlumniCompanies {
  public companies: Company[];
  private URL: { alumniCompanies: string; imgCompany: string };
  public constructor() {
    this.URL = {
      alumniCompanies:
        'https://s3-eu-west-1.amazonaws.com/ih-web/alumni-companies/alumni-companies.json',
      imgCompany:
        'https://s3-eu-west-1.amazonaws.com/ih-web/alumni-companies/logos/'
    };
    this.companies = [];
  }

  public initializeCompanies() {
    return fetch(this.URL.alumniCompanies)
      .then((res: any) => res.json())
      .then((responseCompanies: Company[]) => {
        this.companies = responseCompanies;
      })
      .catch((err: any) => {
        this.companies = [
          {
            company: 'accenture',
            campus: 'madrid',
            alumnus: 'David Molina',
            position: 'Web Developer',
            courseEditionYear: 18,
            courseEditionMonth: 1,
            featured: true
          },
          {
            company: 'google',
            campus: 'madrid',
            alumnus: 'Agustín de la Villa',
            position: 'Account Strategist',
            courseEditionYear: 13,
            courseEditionMonth: 10,
            featured: true,
            top: true
          },
          {
            company: 'visa',
            campus: 'miami',
            alumnus: 'Jimena Quan',
            position: 'Product Manager',
            courseEditionYear: 16,
            courseEditionMonth: 10,
            featured: true,
            top: true
          },
          {
            company: 'twitter',
            campus: 'miami',
            alumnus: 'Andrew Ettinger',
            position: 'Product Manager',
            courseEditionYear: 15,
            courseEditionMonth: 6,
            top: true
          }
        ];
      });
  }

  public getFeaturedAlumniCompanies() {
    return this.getAlumniCompanies(true);
  }

  public getNonFeaturedAlumniCompanies() {
    return this.getAlumniCompanies(false);
  }

  private getAlumniCompanies(getFeatured: boolean) {
    const alumniCompanies = this.companies.filter(({ featured }) =>
      getFeatured ? featured : !featured
    );
    const alumniCompaniesBuild = this.constructorBuild(alumniCompanies);
    return alumniCompaniesBuild;
  }

  private constructorBuild(alumniCompanies: Company[]) {
    return alumniCompanies.map((company: Company) => this.build(company));
  }

  private build(company: Company) {
    const squad = `${this.transformCity(company.campus)}, ${this.transformDate(
      company.courseEditionYear,
      company.courseEditionMonth
    )}`;
    const newAlumniCompany = {
      company: {
        name: company.company,
        image: this.imageCompany(company.company),
        student: {
          name: company.alumnus,
          position: company.position,
          squad
        }
      },
      totalItems: 4
    };
    return newAlumniCompany;
  }

  private transformCity(city: string) {
    return city.slice(0, 3).toUpperCase();
  }

  private transformDate(year: number, month: number) {
    const date = new Date(year, month);
    const cohortDate = new Intl.DateTimeFormat('en-US', {
      year: '2-digit',
      month: 'short'
    }).format(date);
    return cohortDate;
  }

  private imageCompany(company: string) {
    return `${this.URL.imgCompany}${company}.png`;
  }
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Button, Text, Icon, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

import Eyebrow from '../atoms/titles/eyebrow';
import { Title2 } from '../atoms/headers';
import { Text3 } from '../atoms/body';
import ListComponent from '../ListComponent';
import { LayoutExtend } from './styles';
import AlumniCompanies from '../../services/alumniCompanies';
import GraduateCompany from '../GraduateCompany';
import { emitShowMoreCompaniesEvent } from './ga_events';
import Linkedin from '../../../static/assets/career/linkedin.svg';

const alumniCompanies = new AlumniCompanies();

const renderAlumni = (alumni: any) => (
  <ListComponent ChildrenComponent={GraduateCompany} listItems={alumni} />
);

function AlumniGraduates(props: any) {
  const { t } = useTranslation();
  const { ...others } = props;
  const [moreCompanies, setMoreCompanies] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  alumniCompanies.initializeCompanies().finally(() => {
    setIsLoading(true);
  });
  return (
    <LayoutExtend {...others}>
      <Eyebrow isCareerServices>
        {t('career-services:careerServices.mainHeadline.title')}
      </Eyebrow>
      <Title2 as="h2">
        {t('career-services:careerServices.graduatesCompanies.title')}
      </Title2>
      <Text3 as="p">
        {t('career-services:careerServices.graduatesCompanies.description')}
      </Text3>
      {isLoading && (
        <>
          {renderAlumni(alumniCompanies.getFeaturedAlumniCompanies())}
          {moreCompanies ? (
            renderAlumni(alumniCompanies.getNonFeaturedAlumniCompanies())
          ) : (
            <Stack
              align="center"
              direction={['column', null, 'row']}
              justify="center"
              mt={[8, null, 12]}
              spacing={2}
              width="100%"
            >
              <Button
                onClick={() => {
                  emitShowMoreCompaniesEvent();
                  setMoreCompanies(true);
                }}
                variant="link"
                w="auto"
              >
                {t(
                  'career-services:careerServices.graduatesCompanies.loadMore'
                )}
              </Button>
              <Text textStyle="body3">
                {t('career-services:careerServices.graduatesCompanies.or')}
              </Text>
              <Link
                _hover={{ textDecoration: 'none' }}
                as={GatsbyLink}
                isExternal
                to="https://www.linkedin.com/school/ironhack/people/"
              >
                <Button colorScheme="linkedinBlue" variant="outline">
                  {t('career-services:careerServices.graduatesCompanies.cta')}
                  <Icon as={Linkedin} boxSize={77} ml={1} />
                </Button>
              </Link>
            </Stack>
          )}
        </>
      )}
    </LayoutExtend>
  );
}
export default AlumniGraduates;

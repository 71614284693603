import styled from 'styled-components';
import Layout from '../atoms/layout';
import config from '../config';

const LoadMoreCompanies = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4.8rem;
  width: 100%;

  button {
    padding: 3rem 2.4rem;
    width: 32.5rem;
  }
`;
const LayoutExtend = styled(Layout)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 4.8rem;

  & > h2 {
    width: 100%;
  }

  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
    margin-bottom: 3.4rem;
    margin-top: 1.6rem;

    @media (max-width: ${config.resolutions.mobileL}) {
      margin-bottom: 1.6rem;
    }
  }
`;

export { LoadMoreCompanies, LayoutExtend };

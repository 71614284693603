import React from 'react';
import classNames from 'classnames';
import Img from 'gatsby-image';
import Article, { Div, P } from './TeamMember';
import { Text4 } from '../atoms/body';
import Props from '../interfaces/TeamMember';

function TeamMember(props: Props): JSX.Element {
  const { name, profilePicture, jobPosition, description, campus = [] } = props;

  return (
    <Article className={classNames({ description })}>
      <section>
        {profilePicture.fluid ? (
          <Img
            className="profile-picture"
            fluid={profilePicture.fluid}
            alt={name}
          />
        ) : (
          <img src={profilePicture.url} alt={name} />
        )}
        {description && <p>{description}</p>}
      </section>
      <Div>
        <Text4>{name}</Text4>
        <P>{jobPosition.description}</P>
        {jobPosition.code === 'generalManager' && (
          <P>{campus.map(item => item.description).join(' & ')}</P>
        )}
      </Div>
    </Article>
  );
}

export default TeamMember;

import styled from 'styled-components';
import config from '../config';
import Layout from '../atoms/layout';

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.2rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column;
  }
`;

const LayoutExtend = styled(Layout)`
  padding-top: 4.8rem;
  padding-bottom: 8rem;

  & > p {
    color: ${config.palette.darkBlueTransparent('0.6')};
  }
`;

export { LayoutExtend };

import React from 'react';

import Props from '../interfaces/GraduateCompany';
import Article, { Div, SpanPosition, SpanName } from './GraduateCompany';

function GraduateCompany(props: Props) {
  const { company, totalItems } = props;
  const { student } = company;
  return (
    <Article totalItems={totalItems}>
      <img alt={company.name} src={company.image} />
      <Div>
        <SpanPosition>{student.position}</SpanPosition>
        <SpanName>{student.name}</SpanName>
      </Div>
    </Article>
  );
}

export default GraduateCompany;

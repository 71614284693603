import { graphql, useStaticQuery } from 'gatsby';
import {
  PropsDatoStaff,
  DatoCampus,
  PropsJobPosition
} from '../services/interfaces';
import _ from 'lodash';

export const useStaff = (locale: string): Array<PropsDatoStaff> => {
  const { allDatoCmsStaff } = useStaticQuery(
    graphql`
      query {
        allDatoCmsStaff(
          sort: { fields: [jobPosition___position], order: ASC }
        ) {
          edges {
            node {
              campus {
                code
                description
                order
              }
              jobPosition {
                code
                description
                position
              }
              name
              description
              profilePicture {
                fluid(
                  maxWidth: 1440
                  imgixParams: { fm: "jpg", auto: "compress" }
                ) {
                  ...GatsbyDatoCmsFluid
                }
              }
              locale
              scheduleInterviewLink
            }
          }
        }
      }
    `
  );
  const language = locale === 'br' ? 'pt-BR' : locale;
  return allDatoCmsStaff.edges
    .map(edge => edge.node)
    .filter((staffMember: PropsDatoStaff) => staffMember.locale === language);
};

const getCampusCodes = (staffMember: PropsDatoStaff): Array<string> => {
  const campus = _.get(staffMember, 'campus');
  const campuses = _.isArray(campus) ? campus : [campus];
  return campuses.map(campus => campus.code);
};

const getStaffPosition = (staffMember: PropsDatoStaff): PropsJobPosition =>
  _.get(staffMember, 'jobPosition');

const getMainCampus = (staffMember: PropsDatoStaff): DatoCampus => {
  return _.get(staffMember, 'campus[0]');
};

export const staffByCampus = (
  staff: Array<PropsDatoStaff>,
  campusCode: string
): Array<PropsDatoStaff> => {
  return staff.filter(staffMember =>
    getCampusCodes(staffMember).some(
      campus => campus.toLowerCase() === campusCode.toLowerCase()
    )
  );
};

export const staffByPosition = (
  staff: Array<PropsDatoStaff>,
  positions: Array<string>
): Array<PropsDatoStaff> => {
  return staff.filter(staffMember => {
    const staffPosition = getStaffPosition(staffMember).code.toLowerCase();
    const lowerPositions = positions.map(pos => pos.toLowerCase());
    return lowerPositions.some(pos => staffPosition.includes(pos));
  });
};

export const sortStaffByCampus = (
  staff: Array<PropsDatoStaff>
): Array<PropsDatoStaff> => {
  return staff.sort((a, b) => {
    return getMainCampus(a).order - getMainCampus(b).order;
  });
};

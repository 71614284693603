import styled from 'styled-components';
import { PropsComponent } from '../interfaces/GraduateCompany';
import config from '../config';

const size = (totalItems: number) => 100 / totalItems;

export default styled('article')<PropsComponent>`
  width: calc(
    ${props => (props.totalItems > 4 ? 25 : size(props.totalItems))}% - 7.8rem
  );
  margin: 0 3.8rem;

  & > img {
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: ${props => (props.totalItems > 3 ? 50 : size(props.totalItems))}%;
    margin: 0;
  }
`;
export const Div = styled.div`
  margin-top: 0.8rem;
  display: flex;
  flex-direction: column;
`;
export const SpanPosition = styled.span`
  font-size: 1.4rem;
  line-height: 120%;
  opacity: 0.8;

  @media (max-width: ${config.resolutions.mobileL}) {
    line-height: 114%;
  }
`;
export const SpanName = styled(SpanPosition)`
  font-weight: 500;

  @media (max-width: ${config.resolutions.mobileL}) {
    line-height: 171%;
  }
`;
export const SpanSquad = styled.span`
  font-size: 1.2rem;
  line-height: 120%;
  opacity: 0.6;

  @media (max-width: ${config.resolutions.mobileL}) {
    line-height: 133%;
  }
`;

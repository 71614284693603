import React from 'react';
import ListComponent from '../ListComponent';
import TeamMember from '../TeamMember';
import Eyebrow from '../atoms/titles/eyebrow';
import { Title2 } from '../atoms/headers';
import { Text3 } from '../atoms/body';
import Section, { LayoutExtend } from './styles';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useStaff, staffByPosition } from '../../services/datoStaff';

interface Props {
  t: TFunction;
  locale: string;
}

const TeamComponent: React.SFC<any> = (props: Props) => {
  const { t, locale } = props;
  const staff = useStaff(locale);

  const outcomesStaff = staffByPosition(staff, [
    'outcomes',
    'businessDevelopment'
  ]);

  return (
    <LayoutExtend>
      <Eyebrow isCareerServices>{t('eyebrow:eyebrow.careerServices')}</Eyebrow>
      <Title2 as="h2">{t('career-services:careerServices.team.title')}</Title2>
      <Text3 as="p">
        {t('career-services:careerServices.team.description')}
      </Text3>
      <Section>
        <ListComponent
          listItems={outcomesStaff}
          ChildrenComponent={TeamMember}
        />
      </Section>
    </LayoutExtend>
  );
};

export default withTranslation()(TeamComponent);

import styled, { css } from 'styled-components';
import config from '../config';

export const Article = styled.article`
  align-items: center;
  background-color: rgba(244, 145, 2, 0.1);
  border-radius: 0.6rem;
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  line-height: 0.15rem;
  margin-bottom: 3.2rem;
  padding: 3.2rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  & > img {
    width: calc(50% - 3.2rem);
    border-radius: 0.6rem;
  }

  h3 {
    margin-bottom: 1.6rem;
  }

  p {
    opacity: 0.6;
  }

  &:nth-child(4),
  &:nth-child(5) {
    flex-direction: column;
    width: calc(50% - 3.2rem);
    align-items: baseline;
    justify-content: flex-start;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding: 0;
    flex-direction: column-reverse;
    background-color: initial;

    div,
    img {
      width: 100%;
    }

    img {
      margin-bottom: 1.8rem;
    }

    &:nth-child(4),
    &:nth-child(5) {
      width: 100%;
    }

    &:last-child {
      flex-direction: column;
    }
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const DivIframe = styled.div`
  height: 24rem;
  width: 44%;

  & > iframe {
    height: 24rem;
    width: 100%;
    border-radius: 0.6rem;
  }
  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 1.8rem;
  }
`;

const LayoutExtend = css`
  padding-top: 3.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > h2 {
    width: 100%;
    font-size: 4rem;
    line-height: 140%;
    margin-bottom: 3.2rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-top: 3.4rem;
    padding-bottom: 3.2rem;
    background-color: ${config.palette.orangeTransparent('0.1')};

    & > h4 {
      font-size: 2.4rem;
      line-height: 130%;
    }
  }
`;

export { LayoutExtend };

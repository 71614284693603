import React from 'react';
import { Article, Div, DivIframe, LayoutExtend } from './styles';
import Layout from '../atoms/layout';
import Eyebrow from '../atoms/titles/eyebrow';
import { Title2 } from '../atoms/headers';
import { Text1, Text3 } from '../atoms/body';
import { withTranslation } from 'react-i18next';

function WhatCanYouExpect(props: any) {
  const { t } = props;
  return (
    <Layout extend={LayoutExtend}>
      <Eyebrow isCareerServices>{t('eyebrow:eyebrow.careerServices')}</Eyebrow>
      <Title2 as="h2">
        {t('career-services:careerServices.expectations.title')}
      </Title2>
      <Article>
        <Div>
          <Text1 as="h3">
            {t(
              'career-services:careerServices.expectations.description.guidance.title'
            )}
          </Text1>
          <Text3 as="p">
            {t(
              'career-services:careerServices.expectations.description.guidance.description'
            )}
          </Text3>
        </Div>
        <img src="/assets/career/week.png" alt="Ironhack Career Week" />
      </Article>
      <Article>
        <Text1 as="h3">
          {t(
            'career-services:careerServices.expectations.description.jobSearch.title'
          )}
        </Text1>
        <Text3 as="p">
          {t(
            'career-services:careerServices.expectations.description.jobSearch.description'
          )}
        </Text3>
      </Article>
      <Article>
        <Text1 as="h3">
          {t(
            'career-services:careerServices.expectations.description.support.title'
          )}
        </Text1>
        <Text3 as="p">
          {t(
            'career-services:careerServices.expectations.description.support.description'
          )}
        </Text3>
      </Article>
      <Article>
        <img
          src="/assets/career/fast-track-interviews.png"
          alt="Ironhack Career Services"
        />
        <Div>
          <Text1 as="h3">
            {t(
              'career-services:careerServices.expectations.description.hiringFair.title'
            )}
          </Text1>
          <Text3 as="p">
            {t(
              'career-services:careerServices.expectations.description.hiringFair.description'
            )}
          </Text3>
        </Div>
      </Article>
    </Layout>
  );
}

export default withTranslation()(WhatCanYouExpect);
